// @ts-ignore
import { object, union, number, string, array, null_ } from 'valibot';

export type MenuItem = {
  id: number;
  title: string;
  link: string;
  testPixel: string;
  backgroundColor: string;
  textColor: string;
  icon: string;
}
export const MenuItemSchema = object({
  id: number(),
  title: string(),
  link: string(),
  testPixel: string(),
  backgroundColor: string(),
  textColor: string(),
  icon: string(),
});

export type MenuCategory = {
  type: string;
  title: string | null;
  items: MenuItem[]
}
export const MenuCategorySchema = object({
  type: string(),
  title: union([string(), null_()]),
  items: array(MenuItemSchema),
});

export type FetchMenusResponse = MenuCategory[]
export const FetchMenusSchema = array(MenuCategorySchema);
