// @ts-ignore
import { object, literal, union, number, string, array, null_, unknown, boolean } from 'valibot';
import type { AuthorOrExpert } from './authorOrExpert';
import { AuthorOrExpertSchema } from './authorOrExpert';
import type { Category } from './category';
import type { Tag } from './tag';
import { TagSchema } from './tag';
import { TContentSchema } from './content';

export type Article = {
  id: number;
  title: string;
  type: 'health';
  name: string;
  url: string;
  date: number;
  teaser_url: string;
  excerpt: string;
  author: null | AuthorOrExpert;
  expert: null | AuthorOrExpert;
  categories: Category[];
  tags: Tag[];
  is_advertising: boolean;
  is_promo: boolean;
  // img: any[];
  // image: null;
  // default_img: any[];
  caption: string;
  comments_enabled: boolean;
  comments_count: number;
  ab_titles_data: {
    titles: string;
    clicks: string;
    views: string
  };
  views: number;
  ads_token: string;
  reactions_enabled: boolean;
  test_pixel: string;
  custom_authors: string;
  advert_label: string;
  template: 'one-column' | 'two-column' | 'three-column' | 'card' | 'promo' | 'broadside' | 'toc';
  json_ld: string;
  amp_enabled: boolean;
  last_modified: number;
  parsed_content: TContent;
}

export const ArticleSchema = object({
  id: number(),
  title: string(),
  type: literal('health'),
  name: string(),
  url: string(),
  teaser_url: string(),
  date: number(),
  excerpt: string(),
  author: union([null_(), AuthorOrExpertSchema]),
  expert: union([null_(), AuthorOrExpertSchema]),

  // TODO: what
  categories: unknown(),
  tags: array(TagSchema),
  is_advertising: boolean(),
  is_promo: boolean(),
  // img: any[],
  // image: null,
  // default_img: any[],
  caption: string(),
  comments_enabled: boolean(),
  comments_count: number(),
  ab_titles_data: object({
    titles: string(),
    clicks: string(),
    views: string(),
  }),
  views: number(),
  ads_token: string(),
  reactions_enabled: boolean(),
  test_pixel: string(),
  custom_authors: string(),
  advert_label: string(),
  template: union([
    literal('one-column'),
    literal('two-column'),
    literal('three-column'),
    literal('card'),
    literal('promo'),
    literal('broadside'),
    literal('toc'),
  ]),
  json_ld: string(),
  amp_enabled: boolean(),
  last_modified: number(),
  parsed_content: array(TContentSchema),
});
