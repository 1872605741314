// @ts-ignore
import { object, array } from 'valibot';
import type { Meta } from './meta';
import type { Article } from './article';
import { MetaSchema } from './meta';
import { ArticleSchema } from './article';
import type { SeoIronMaiden } from './seo.ironMaiden';
import { SeoIronMaidenSchema } from './seo.ironMaiden';

export const FetchHomeSchema = object({
  meta: MetaSchema,
  seo: SeoIronMaidenSchema,
  items: array(ArticleSchema),
});

export type FetchHomeResponse = {
  seo: SeoIronMaiden;
  meta: Meta;
  items: Article[];
}
