// @ts-ignore
import { object, number, array, boolean, optional } from 'valibot';
import type { Breadcrumb } from './breadcrumb';
import { BreadcrumbSchema } from './breadcrumb';

export type Meta = {
  is_user_logged_in: boolean;
  total_items: number;
  total_pages: number;
  current_page: number;
  limit: number;
  offset: number;
}

export type MetaWithBreadcrumb = Meta & {
  breadcrumb: Breadcrumb[];
}

export const MetaSchema = object({
  is_user_logged_in: boolean(),
  total_items: number(),
  total_pages: number(),
  current_page: number(),
  limit: number(),
  offset: number(),
  breadcrumb: optional(array(BreadcrumbSchema)),
});
