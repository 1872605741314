// @ts-ignore
import { object, union, number, string, array, null_, optional, boolean } from 'valibot';

export type MetaIronMaiden = IArticleMetaServerResponseIronMaiden;

export const MetaIronMaidenSchema = object({
  adfox_params: object({
    lh_adfox_terms: string(),
    lh_adfox_tags: string(),
    lh_adfox_streams: string(),
    lh_adfox_noad: string(),
    lh_adfox_noadsence: optional(union([string(), number()])),
    lh_adfox_post_id: optional(string()),
    lh_adfox_pk: string(),
    lh_adfox_homepage: boolean(),
  }),
  main_color: union([null_(), string()]),
  jumper_scroller: union([null_(), object({
    images: union(
      [
        null_(),
        object({
          desktop: string(),
          tablet: string(),
          mobile: string(),
        }),
      ]),
    name: union([null_(), string()]),
    pitch: union([null_(), string()]),
    url: union([null_(), string()]),
    isActive: union([null_(), boolean()]),
  })]),
  lh_first_publish_date: string(),
  ads_source: object({
    url: union([null_(), string()]),
    text: union([null_(), string()]),
  }),
  advert: object({
    first_test_pixel: union([null_(), string()]),
    second_test_pixel: union([null_(), string()]),
  }),
  cover_source: object({
    url: union([null_(), string()]),
    text: union([null_(), string()]),
  }),
  source: object({
    url: union([null_(), string()]),
    text: union([null_(), string()]),
  }),
  promo: object({
    bgColor: union([null_(), string()]),
    url: union([null_(), string()]),
    images: union([object({
      topDesktop: union([null_(), string()]),
      topMobile: union([null_(), string()]),
      bottomDesktop: union([null_(), string()]),
      bottomMobile: union([null_(), string()]),
    }), null_()]),
    first_test_pixel: union([null_(), string()]),
    second_test_pixel: union([null_(), string()]),
  }),
  health_legacy: boolean(),
  custom_authors: string(),
  breadcrumb: array(object({ title: string(), url: string() })),
});
