// @ts-ignore
import { object, string } from 'valibot';

export type Breadcrumb = {
  title: string;
  url: string;
}

export const BreadcrumbSchema = object({
  title: string(),
  url: string(),
});
