// @ts-ignore
import { literal, union } from 'valibot';

export type PageType = 'post' | 'page' | 'health' | 'recipe' | 'category-health' | 'post_tag'

export const PageTypeSchema = union([
  literal('post'),
  literal('page'),
  literal('health'),
  literal('recipe'),
  literal('category-health'),
]);
