// @ts-ignore
import { object, union, number, string, array, null_, lazy } from 'valibot';
import type { PageType } from './pageType';
import { PageTypeSchema } from './pageType';
import { TContentSchema } from './content';

export type Category = {
  id: number;
  type: PageType;
  title: string;
  slug: string;
  url: string;
  parsed_description: TContent[];
  // color: string | null; // не используется
  icon: string | null;
  image: string | null;
  // og_image: string | null; // не используется
  parent: Category | null;
}

export const CategorySchema = object({
  id: number(),
  type: PageTypeSchema,
  title: string(),
  slug: string(),
  url: string(),
  parsed_description: array(TContentSchema),
  // color: union([null_(), string()]),
  icon: union([null_(), string()]),
  image: union([null_(), string()]),
  // og_image: union([null_(), string()]),
  parent: union([null_(), lazy(() => CategorySchema)]),
});
