// @ts-ignore
import { object } from 'valibot';
import type { Article } from './article';
import { ArticleSchema } from './article';
import type { MetaIronMaiden } from './meta.ironMaiden';
import { MetaIronMaidenSchema } from './meta.ironMaiden';
import type { SeoIronMaiden } from './seo.ironMaiden';
import { SeoIronMaidenSchema } from './seo.ironMaiden';

export type FetchSinglePostResponse = {
  item: Article,
  meta: MetaIronMaiden,
  seo: SeoIronMaiden,
}

export const FetchSinglePostSchema = object({
  item: ArticleSchema,
  meta: MetaIronMaidenSchema,
  seo: SeoIronMaidenSchema,
});
