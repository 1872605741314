// @ts-ignore
import { object, array } from 'valibot';
import type { Article } from './article';
import { ArticleSchema } from './article';
import type { MetaIronMaiden } from './meta.ironMaiden';
import { MetaIronMaidenSchema } from './meta.ironMaiden';
import type { SeoIronMaiden } from './seo.ironMaiden';
import { SeoIronMaidenSchema } from './seo.ironMaiden';

export type FetchArticlesResponse = {
  items: Article[],
  meta: MetaIronMaiden,
  seo: SeoIronMaiden,
}

export const FetchArticlesSchema = object({
  items: array(ArticleSchema),
  meta: MetaIronMaidenSchema,
  seo: SeoIronMaidenSchema,
});
