// @ts-ignore
import { object, union, number, string, null_ } from 'valibot';

export type AuthorOrExpert = {
  name: string;
  avatar: string;
  url: string;
  profile: string;
  about: string | null;
  expertise: string | null;
  integer_id: number,
  telegram: string | null;
  vkontakte: string | null;
  twitter: string | null;
}

export const AuthorOrExpertSchema = object({
  integer_id: number(),
  name: string(),
  avatar: string(),
  url: string(),
  profile: string(),
  about: union([null_(), string()]),
  expertise: union([null_(), string()]),
  telegram: union([null_(), string()]),
  vkontakte: union([null_(), string()]),
  twitter: union([null_(), string()]),
});
