// @ts-ignore
import { object, optional, array } from 'valibot';
import type { Category } from './category';
import type { Article } from './article';
import type { MetaIronMaiden } from './meta.ironMaiden';
import type { SeoIronMaiden } from './seo.ironMaiden';
import { ArticleSchema } from './article';
import { MetaIronMaidenSchema } from './meta.ironMaiden';
import { SeoIronMaidenSchema } from './seo.ironMaiden';
import { CategorySchema } from './category';

export type FetchByTagResponse = {
  item?: Category;
  items: Article,
  meta: MetaIronMaiden,
  seo: SeoIronMaiden,
}

export const FetchByTagSchema = object({
  item: optional(CategorySchema),
  items: optional(array(ArticleSchema)),
  meta: optional(MetaIronMaidenSchema),
  seo: optional(SeoIronMaidenSchema),
});
