import type { Context } from '@nuxt/types';

import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import { attachLoggerToAxios } from '@devhacker/shared/libs/logger';
import { ICacheClient } from './cache-client.interface';
import { TIMEOUT, FETCH_WIDGETS, FETCH_HEALTH_WIDGETS, FETCH_RECIPE_WIDGETS } from '~/constants';

import { formattingIMApitoDefaultApi } from '~/utils/formattingData';
import { API_GATEWAY } from '~/constants/config';
import {
  POST_WIDGETS,
  RECIPE_POST_WIDGETS,
  HEALTH_POST_WIDGETS,
} from '~/constants/widgets';

const getCacheData = async (cacheKey: string, cacheClient?: ICacheClient): Promise<string | null> => {
  if (!cacheClient) {
    return null;
  }

  const cachedData = await cacheClient.get(`store:${cacheKey}`);
  return cachedData ? JSON.parse(cachedData) : null;
};

const redirectIfNeeded = (redirect: Function, status: number, location: string) => {
  const redirectCodes = [301, 302, 303, 307, 308];
  if (redirectCodes.includes(status) && location) {
    redirect(status, location);
    return true;
  }
  return false;
};

type Params = {
  $axios: Context['$axios'],
  $logger: Context['$logger'],
  $sentry: Context['$sentry'],
  redirect: Context['redirect']
  app: Context['app']
}

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Accept-Encoding': 'identity',
};

export default ({ $axios, $logger, $sentry, app, redirect }: Params) => {
  const apiGatewayRequest = (): NuxtAxiosInstance => {
    const headers = {} as any;

    headers.Accept = 'application/json';
    headers['Accept-Encoding'] = 'identity';
    headers['Content-type'] = 'application/json';

    const axiosInstance = $axios.create({
      headers,
      baseURL: API_GATEWAY,
      timeout: TIMEOUT,
    }) as NuxtAxiosInstance;

    attachLoggerToAxios(axiosInstance, $logger, 'api-gateway');

    // @ts-ignore
    axiosInstance.interceptors.response.use((response) => {
      response.data = response.data.map((item: any) => {
        item.body = JSON.parse(item.body);
        return item;
      });

      return response;
    });

    axiosInstance.onError((e: any) => {
      $sentry.captureException(e);
    });

    return axiosInstance;
  };

  return {
    apiGatewayRequest: apiGatewayRequest(),
    async getArticleData (slug: string) {
      // Страницы здоровья приходят без слэша, добавляем по необходимости
      const slugWithSlash = slug.startsWith('/') ? slug : `/${slug}`;

      const pipeline = [
        {
          method: 'GET',
          path: `/posts/by-slug/v2${slugWithSlash}`,
          headers,
        },
        {
          headers: {
            'x-app': 'lifehacker-web',
            ...headers,
          },
          method: 'GET',
          // format_ver=1 означает что ответ будет плоским, без children
          path: `/comments/by-post-slug${slugWithSlash}?format_ver=1&sort=oldest`,
        },
      ];

      const key = `${FETCH_WIDGETS}${JSON.stringify(POST_WIDGETS)}`;
      const widgetsCache = await getCacheData(key, app.$redisClient);

      if (!widgetsCache && !app.store!.state.widgets.footer) {
        pipeline.push({
          method: 'GET',
          path: `/posts/widgets/?includes=${POST_WIDGETS.join(',')}`,
          headers,
        });
      }

      const response = await this.apiGatewayRequest.post('batch-requests', { pipeline });

      const preparedResponse = {
        article: response.data[0],
        comments: response.data[1],
        widgets: response.data[2] ?? widgetsCache,
      };

      if (preparedResponse.article.status === 200) {
        preparedResponse.article.body = formattingIMApitoDefaultApi(preparedResponse.article.body);
      }

      const { status, location } = preparedResponse.article.body || {};
      if (redirectIfNeeded(redirect, status, location)) { return; }

      return preparedResponse;
    },
    async getHealthArticleData (slug: string) {
      const pipeline = [
        {
          method: 'GET',
          path: `/health/by-slug/${slug}`,
          headers,
        },
        {
          headers: {
            ...headers,
            'x-app': 'lifehacker-web',
          },
          method: 'GET',
          // format_ver=1 означает что ответ будет плоским, без children
          path: `/comments/by-health-slug/${slug}?format_ver=1&sort=oldest`,
        },
      ];

      const key = `${FETCH_HEALTH_WIDGETS}${JSON.stringify(HEALTH_POST_WIDGETS)}`;
      const widgetsCache = await getCacheData(key, app.$redisClient);

      if (!widgetsCache) {
        pipeline.push({
          headers,
          method: 'GET',
          path: `/posts/widgets/?includes=${HEALTH_POST_WIDGETS.join(',')}`,
        });
      }

      const response = await this.apiGatewayRequest.post('batch-requests', { pipeline });

      const preparedResponse = {
        article: response.data[0],
        comments: response.data[1],
        widgets: response.data[2] ?? widgetsCache,
      };

      if (preparedResponse.article.status === 200) {
        preparedResponse.article.body = formattingIMApitoDefaultApi(preparedResponse.article.body);
      }

      const { status, location } = preparedResponse.article.body || {};
      if (redirectIfNeeded(redirect, status, location)) { return; }

      return preparedResponse;
    },
    async getRecipeData (slug: string) {
      const pipeline = [
        {
          method: 'GET',
          path: `/recipes/by-slug/${slug}`,
          headers,
        },
        {
          headers: {
            ...headers,
            'x-app': 'lifehacker-web',
          },
          method: 'GET',
          // format_ver=1 означает что ответ будет плоским, без children
          path: `/comments/by-recipe-slug/${slug}?format_ver=1&sort=oldest`,
        },
      ];

      const key = `${FETCH_RECIPE_WIDGETS}${JSON.stringify(RECIPE_POST_WIDGETS)}`;
      const widgetsCache = await getCacheData(key, app.$redisClient);

      if (!widgetsCache && !app.store!.state.widgets.recipesAboveComments) {
        pipeline.push({
          headers,
          method: 'GET',
          path: `/recipes/widgets/?includes=${RECIPE_POST_WIDGETS.join(',')}`,
        });
      }

      const response = await this.apiGatewayRequest.post('batch-requests', { pipeline });
      const preparedResponse = {
        recipe: response.data[0],
        comments: response.data[1],
        widgets: response.data[2] ?? widgetsCache,
      };

      const { status, location } = preparedResponse.recipe.body || {};
      if (redirectIfNeeded(redirect, status, location)) { return; }

      return preparedResponse;
    },
  };
};
