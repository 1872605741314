// @ts-ignore
import { object, string } from 'valibot';

export type SeoIronMaiden = SeoServerResponseIronMaiden;

export const SeoIronMaidenSchema = object({
  title: string(),
  description: string(),
  robots: string(),
  canonical: string(),
  og_image: string(),
  site_icon: string(),
  // TODO узнать используем ли мы schema где-то, если нет - попросить бэк выпилить/убрать из valibot'а
  schema: string(),
});
