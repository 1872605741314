// @ts-ignore
import { object, number, string } from 'valibot';

export type Tag = {
  id: number;
  type: string;
  title: string;
  slug: string;
  url: string;
}

export const TagSchema = object({
  id: number(),
  type: string(),
  title: string(),
  slug: string(),
  url: string(),
});
