// @ts-ignore
import { object, array, optional } from 'valibot';
import type { Category } from './category';
import type { Article } from './article';
import type { MetaWithBreadcrumb } from './meta';
import type{ SeoIronMaiden } from './seo.ironMaiden';
import { CategorySchema } from './category';
import { ArticleSchema } from './article';
import { MetaSchema } from './meta';
import { SeoIronMaidenSchema } from './seo.ironMaiden';

export type FetchCategoryResponse = {
  item?: Category;
  items?: Article[];
  meta?: MetaWithBreadcrumb;
  seo?: SeoIronMaiden;
}

export const fetchCategorySchema = object({
  item: optional(CategorySchema),
  items: optional(array(ArticleSchema)),
  meta: optional(MetaSchema),
  seo: optional(SeoIronMaidenSchema),
});
