// @ts-ignore
import { object, literal, union, number, string, array, lazy, unknown, record } from 'valibot';

export const TContentSchema = object({
  type: string(),
  id: number(),
  parser_type: union([literal('gutenberg_parser'), literal('html_parser')]),
  text: string(),
  attributes: record(unknown()),
  children: array(lazy(() => TContentSchema)),
});
