// @ts-ignore
import { object, number, string } from 'valibot';
import type { PageType } from './pageType';
import { PageTypeSchema } from './pageType';

export type FetchTypeBySlugResponse = {
  id: number;
  type: PageType
  slug: string,
  object: string,
  url: string
}

export const FetchTypeBySlugSchema = object({
  id: number(),
  type: PageTypeSchema,
  slug: string(),
  object: string(),
  url: string(),
});
